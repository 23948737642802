<template>
  <dashboard-page-wrapper title="Team">
    <team-menu activeMenu="Team"/>
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-0">
          <header class="py-1">
            <div class="d-flex justify-content-between px-4 py-2 align-items-center">
              <div class="d-flex align-items-center">
                <div>
                  <span class="font-weight-bold mr-3 text-nowrap">{{ seatsData.length }}/{{ totalRecruiterSeats }} seats filled</span>
                  <manage-seats/>
                </div>
                <div class="d-flex align-items-start">
                  <el-input v-model="searchRecruiter" @input="handleSearch" class="search-form ml-3" clearable>
                    <template #prefix>
                      <el-tooltip effect="dark" placement="top-start" content="Search tags, email, names, and numbers">
                        <i class="el-input__icon el-icon-search text-dark font-weight-bold"></i>
                      </el-tooltip>
                    </template>
                  </el-input>
                </div>
              </div>
              <button class="btn btn-primary" @click="showInvitationModal">
                <i class="fas fa-user-plus"></i> Invite
              </button>
            </div>
          </header>
          <div>
            <team-table
              :seats="filteredRecruiterSeats"
              :integration="atsIntegration"
              :integrationEnabled="atsIntegrationEnabled"
            ></team-table>
          </div>
        </card>
      </b-col>
    </b-row>
    <invite-member-modal ref="invitationModal" :seatsFilled="seatsFilled" :usedEmails="usedEmails"/>
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {mapActions, mapGetters} from 'vuex';
import TeamTable from './TeamTable.vue';
import InviteMemberModal from './InviteMemberModal';
import {FETCH_ATS_INTEGRATION_REQUEST, FETCH_SEATS_REQUEST} from '@/store/storeActions';
import {seatRoles, seatStatus, seatTypes, supportUrl} from '@/store/userConstants';
import ManageSeats from './ManageSeats.vue';
import TeamMenu from './TeamHeaderMenu.vue';

export default {
  components: {
    DashboardPageWrapper,
    TeamTable,
    InviteMemberModal,
    ManageSeats,
    TeamMenu,
  },
  data() {
    return {
      searchRecruiter: '',
      filteredRecruiterSeats: [],
      helpUrl: `${supportUrl}5925486-how-to-manage-your-team-members`,
      seatTypes,
    };
  },
  computed: {
    ...mapGetters([
      'totalSeats',
      'availableSeats',
      'usedSeats',
      'accountSeats',
      'accountFreeUsersLoaded',
      'atsIntegration',
      'atsIntegrationEnabled',
      'integrationLoaded',
    ]),
    seats() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.RECRUITER).filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION ||
          seat.status === seatTypes.NON_TEXTER).map((seat) => {
        // Ensure each seat has a tags property (even if it's empty)
        if (!seat.tags) {
          seat.tags = [];  // Assign an empty array if tags are missing
        }
        return seat;  // Return the modified seat
      });
    },
    seatsData() {
      return this.accountSeats.filter((seat) => seat.seatRole === seatRoles.RECRUITER).filter(
        (seat) => seat.seatType !== seatTypes.NON_TEXTER && (seat.status === seatStatus.USED ||
          seat.status === seatStatus.PENDING_INVITATION));
    },
    usedEmails() {
      return this.seats.map((seat) => seat.metadata ? seat.metadata.invitedEmail : '');
    },
    totalRecruiterSeats() {
      return this.accountSeats.filter(
        (seat) => seat.seatRole === seatRoles.RECRUITER && seat.seatType === seatTypes.TEXTER).length;
    },
    usedRecruiterSeats() {
      return this.accountSeats.filter(
        (seat) => seat.seatRole === seatRoles.RECRUITER && seat.seatType === seatTypes.TEXTER).filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION).length;
    },
    seatsFilled() {
      return this.usedRecruiterSeats >= this.totalRecruiterSeats;
    },
  },
  watch: {
    searchRecruiter() {
      // Automatically call handleSearch when searchRecruiter changes
      this.handleSearch();
    },
    seats: {
      immediate: true, // Run the watcher immediately when component is created
      handler(newSeats) {
        this.filteredRecruiterSeats = newSeats; // Update the filteredRecruiterSeats whenever `seats` changes
      }
    }
  },
  mounted() {
    !this.integrationLoaded && this.FETCH_ATS_INTEGRATION_REQUEST();
  },
  methods: {
    ...mapActions([
      FETCH_SEATS_REQUEST,
      FETCH_ATS_INTEGRATION_REQUEST,
    ]),
    showInvitationModal: function() {
      this.$refs.invitationModal.displayToggle(true);
    },
    handleSearch() {
      // Convert the search query to lowercase for case-insensitive matching
      const searchQuery = this.searchRecruiter.toLowerCase();

      // Split the search query by spaces or commas to handle multiple tags search
      const searchTerms = searchQuery.split(/[\s,]+/).filter((term) => term);
      // Filter the hiring managers based on the search query
      this.filteredRecruiterSeats = this.seats.filter((manager) => {
        const fullName = manager.user ? `${(manager.user.firstName || '').toLowerCase()} ${(manager.user.lastName ||
          '').toLowerCase()}` : '';
        const nameMatch = fullName.includes(searchQuery);
        const emailMatch = (manager.user ? manager.user.email : (manager.metadata.invitedEmail || '')).toLowerCase().
          includes(searchQuery);
        const numberMatch = (manager.twilioNumber || '').toLowerCase().includes(searchQuery);
        // Check if any of the tags match any of the search terms
        const tagsMatch = searchTerms.some((term) => manager.tags.some((tag) => tag.toLowerCase().includes(term)),
        );
        return nameMatch || emailMatch || numberMatch || tagsMatch;
      });
    },
  },
  created() {
    !this.accountFreeUsersLoaded && this.FETCH_SEATS_REQUEST();
    setTimeout(() => {
      this.filteredRecruiterSeats = this.seats;
    }, 100);
  },
};
</script>
