<template>
  <div>
    <el-table
      ref="teamTable"
      class="table-responsive table-flush mb-4 members-table"
      header-row-class-name="thead-light"
      :data="seats"
      selectable
      highlight-current-row
      @current-change="handleCurrentChange"
      v-click-outside="clearSelection"
      empty-text="Send an invite to start filling your seats"
      :cell-style="{height: '58px'}"
      :default-sort="{ prop: 'name', order: 'descending' }"
    >
      <el-table-column label="Name" prop="name" sortable :sort-method="sortByName">
        <template v-slot="{ row }">
          {{ (row.user) ? row.user.firstName + ' ' + ((row.user.lastName) ? row.user.lastName : '') : '' }}
          {{ (row.user) && (row.user.id) && (userId === row.user.id) ? '(you)' : ''}}
      </template>
      </el-table-column>
      <el-table-column label="Email" prop="email" sortable :sort-method="sortByEmail">
        <template v-slot="{ row }">
          <el-tooltip effect="dark" placement="bottom-start"
          :content="(row.user) ? row.user.email : row.metadata.invitedEmail">
            <span>{{ (row.user) ? row.user.email : row.metadata.invitedEmail }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Number" prop="phoneNumber">
        <template v-slot="{ row }">
          {{ row.twilioNumberFormatted ? row.twilioNumberFormatted :
            row.seatType === seatTypes.NON_TEXTER ? 'Non texter' : ' - ' }}
        </template>
      </el-table-column>
      <el-table-column label="Status" prop="userType" sortable :sort-method="sortByStatus">
        <template v-slot="{ row }">
          <b-row class="align-items-center">
            <b-col>
              {{ row.userType === userTypes.ADMIN ? 'Admin': 'Member' }}
            </b-col>
            <b-col v-if="row.status === seatStatus.PENDING_INVITATION">
              <b-badge class="invite-sent-badge">
                Invitation Pending
              </b-badge>
            </b-col>
            <b-col v-if="row.user && row.user.outOfOfficeMessageEnabled">
              <b-badge class="out-of-office-badge">
                Out of Office
              </b-badge>
            </b-col>
          </b-row>
        </template>
      </el-table-column>

      <!-- Tags Column -->
      <el-table-column label="Tags" prop="tags" :width="columnWidths.tags">
        <template v-slot="{ row }">
          <div v-if="isEditTags && currentEditRow === row.id">
            <div class="edit-tags-on" :style="getTagStyles(row)">
              <!-- Editable Tags -->
              <tags-input
                type="text"
                v-model="row.tags"
                class="tags-input"
                ref="tagsInputRef"
              />
              <!-- Add a custom close button to clear all tags -->
              <div v-if="row.tags.length > 0" @click="clearTags(row)" class="tags-close-icon">
                <img :src="closeImg" />
              </div>
            </div>
            <div class="d-flex justify-content-start hiring-managers-tags-btns mt-2">
              <button class="btn btn-sm btn-primary" @click="saveTags(row.id, row)">
                Save
              </button>
              <button class="btn btn-outline-primary" @click="cancelEditTags(row)">
                Cancel
              </button>
            </div>
          </div>

          <!-- Display Tags Section -->
          <div v-else class="d-flex align-items-end hiring-managers-tags justify-content-between">
            <div class="d-flex flex-wrap align-items-end justify-content-start" :style="getTagStyles(row)">
              <div v-for="(tag, index) in row.tags"
                :key="index"
                class="mr-2 tag-row mt-2"
              >
                {{ tag }}
              </div>
            </div>

            <!-- Edit icon -->
            <div class="hiring-managers-tags-edit ml-3" @click="handleEditTags(row.id, row)">
              <i class="fas fa-pencil-alt"> </i>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- Menu Start -->
      <el-table-column label="Actions" prop="actions" >
        <template slot-scope="scope" class="active-element" v-if="scope.row.userId !== userId ||
        scope.row.twilioNumber != null && scope.row.userType === userTypes.ADMIN">
          <el-dropdown trigger="click" v-if="scope.row.userId !== userId ||
          scope.row.seatType !== seatTypes.NON_TEXTER">
            <span>
              <i class="fas fa-ellipsis-h mt-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="scope.row.userId !== userId && !hasInvitedUsers"
              @click.native="tryRemoveSeat(scope.row)">
                Remove
              </el-dropdown-item>
              <el-dropdown-item v-if="hasPermissionMakeAdmin" @click.native="tryChangeRole()">
                Make Admin
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.userId !== userId && hasPermissionMakeMember"
              @click.native="tryChangeRole()">
                Make Member
              </el-dropdown-item>
              <el-dropdown-item v-if="hasAdminNumber" @click.native="tryResetNumber()">
                Reset your number
              </el-dropdown-item>
              <el-dropdown-item v-if="hasNumber" @click.native="tryResetNumber()">
                Reset number
              </el-dropdown-item>
              <el-dropdown-item v-if="hasInvitedUsers" @click.native="resendInvitation(scope.row)">
                Resend invite
              </el-dropdown-item>
              <el-dropdown-item v-if="hasInvitedUsers" @click.native="tryRemoveSeat(scope.row)">
                Cancel invite
              </el-dropdown-item>
              <el-dropdown-item v-if="!isOutOfOfficeUser && scope.row.twilioNumber"
               @click.native="turnOnModalOpen(scope.row)">
                Turn on Out of Office
              </el-dropdown-item>
              <el-dropdown-item v-if="isOutOfOfficeUser && scope.row.twilioNumber"
              @click.native="turnOffModalOpen(scope.row)">
                Turn off Out of Office
              </el-dropdown-item>
              <el-dropdown-item v-if="usedSeats <= totalSeats && scope.row.user &&
              scope.row.seatType === seatTypes.NON_TEXTER"
              @click.native="turnOnTexterModalOpen(scope.row)">
                Make Texter
              </el-dropdown-item>
              <el-dropdown-item v-if="!scope.row.twilioNumber && scope.row.userId !== userId &&
               scope.row.seatType !== seatTypes.NON_TEXTER && (scope.row.user &&
               scope.row.userType === userTypes.ADMIN)"
              @click.native="turnOnTexterModalOpen(scope.row)">
                Make Non texter
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <!-- Menu End -->
    </el-table>
    <confirmation-modal
      ref="deletionConfirmationModal"
      :header="confirmData.header"
      :question="confirmData.question"
      :confirmBtnText="confirmData.acceptTxt"
      :cancelBtnText="confirmData.rejectTxt"
      confirmBtnType="danger"
      @on-accept="removeSeat"
    />
    <confirmation-modal
      ref="resetNumberModal"
      :header="resetData.header"
      :question="resetData.question"
      :confirmBtnText="resetData.acceptTxt"
      :cancelBtnText="resetData.rejectTxt"
      confirmBtnType="danger"
      @on-accept="resetNumber"
    />
    <confirmation-modal
      ref="makeAdminModal"
      :header="roleData.header"
      :question="roleData.question"
      :confirmBtnText="roleData.acceptTxt"
      :cancelBtnText="roleData.rejectTxt"
      :confirmBtnType="roleData.btnTxt"
      @on-accept="changeUserType"
    />
    <confirmation-modal
      ref="turnOffOutOfOfficeModal"
      :header="turnOffOfficeData.header"
      :question="turnOffOfficeData.question"
      :confirmBtnText="turnOffOfficeData.acceptTxt"
      :cancelBtnText="turnOffOfficeData.rejectTxt"
      confirmBtnType="danger"
      @on-accept="turnOfOffice"
    />
    <confirmation-modal
      ref="turnOnTexterModal"
      :header="turnOnTexter.header"
      :question="turnOnTexter.question"
      :confirmBtnText="turnOnTexter.acceptTxt"
      :cancelBtnText="turnOnTexter.rejectTxt"
      @on-accept="updateUser"
    />
    <turnon-office-modal ref="turnOnOutOfficeModal"
      :activeUser="currentSeat"
    />
  </div>
</template>
<script>
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from 'element-ui';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {
  REMOVE_SEAT_REQUEST,
  CHANGE_USER_TYPE_REQUEST,
  RESET_NUMBER_REQUEST,
  FETCH_USER_OFFICE_MSG_REQUEST,
  UPDATE_USER_OFFICE_MSG_REQUEST,
  UPDATE_USER_TO_TEXTER,
  UPDATE_SEAT_TAGS,
} from '@/store/storeActions';
import {mapActions, mapGetters} from 'vuex';
import {resendInvitationApi} from '@/api/accountApi.js';
import appNotification from '@/util/appNotification';
import {atsIntegrationsList, seatStatus, userTypes, seatTypes} from '@/store/userConstants';
import TurnonOfficeModal from './TurnonOfficeModal.vue';
import TagsInput from '@/components/Inputs/TagsInput.vue';

export default {
  components: {
    ConfirmationModal,
    TurnonOfficeModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TagsInput,
  },
  props: {
    seats: {
      type: Array,
      default: () => [],
    },
    integration: Object,
    integrationEnabled: Boolean,
  },
  data: function() {
    return {
      currentSeat: null,
      seatStatus: seatStatus,
      userTypes: userTypes,
      seatTypes: seatTypes,
      columnWidths: {
        tags: '400px',
      },
      isEditTags: false,
      closeImg: 'img/tools/close.svg',
      currentEditRow: null,
    };
  },
  computed: {
    confirmData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      if (this.currentSeat) {
        if (this.currentSeat.status === seatStatus.USED || this.currentSeat.seatType === seatTypes.NON_TEXTER) {
          data.header = 'Remove team member';
          data.question = `<div class="px-1">This team member will be removed permanently and will immediately lose access to their account. You can refill the seat with a different member by sending a new invite.</div>`;
          data.acceptTxt = 'Confirm removal';
          data.rejectTxt = 'Cancel';
        } else if (this.currentSeat.status === seatStatus.PENDING_INVITATION) {
          data.header = 'Cancel invite';
          data.question = 'Are you sure you’d like to cancel this invite?';
          data.acceptTxt = 'Cancel invite';
          data.rejectTxt = 'Back';
        }
      }
      return data;
    },
    resetData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      if (this.currentSeat && this.currentSeat.user) {
        if (this.currentSeat.user.id === this.userId) {
          data.header = 'Reset your number';
          data.question = `<div class="px-1">This will permanently remove your current Keeyora number, and you will be able to choose a new number by clicking ‘Get a number’ in the Account tab. Are you sure you’d like to reset your Keeyora number?</div>`;
          data.acceptTxt = 'Reset number';
          data.rejectTxt = 'Cancel';
        } else if (this.currentSeat.user.id !== this.userId) {
          data.header = 'Reset number';
          data.question = `<div class="px-1">This team member will permanently lose their current Keeyora number, and they will be able to choose a number by clicking ‘Get a number’ in the Account tab. Are you sure you’d like to reset their Keeyora number?</div>`;
          data.acceptTxt = 'Reset number';
          data.rejectTxt = 'Cancel';
        }
      }
      return data;
    },
    roleData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
        btnTxt: '',
      };
      if (this.currentSeat && this.currentSeat.user) {
        if (this.currentSeat.userType === userTypes.ADMIN) {
          data.header = 'Make Member';
          data.question = `<div class="px-1">This Admin will lose all admin abilities and become a regular Member.</div>`;
          data.acceptTxt = 'Make Member';
          data.rejectTxt = 'Cancel';
          data.btnTxt= 'danger';
        } else if (this.currentSeat.userType === userTypes.MEMBER) {
          data.header = 'Make Admin';
          data.question = `<div class="px-1">This team member will now have all admin abilities.</div>`;
          data.acceptTxt = 'Make Admin';
          data.rejectTxt = 'Cancel';
          data.btnTxt= 'primary';
        }
      }
      return data;
    },
    turnOffOfficeData: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      if (this.currentSeat && this.currentSeat.user) {
        const fullName = this.currentSeat.user.firstName +' '+ this.currentSeat.user.lastName;
        data.header = 'Turn off Out of Office for '+ fullName;
        data.question = `<div class="px-1">Are you sure you’d like to turn off Out of Office for ` + fullName +`?</div>`;
        data.acceptTxt = 'Turn Off';
        data.rejectTxt = 'Cancel';
        data.btnTxt= 'danger';
      }
      return data;
    },
    turnOnTexter: function() {
      const data = {
        header: '',
        question: '',
        acceptTxt: '',
        rejectTxt: '',
      };
      if (this.currentSeat && this.currentSeat.seatType === seatTypes.NON_TEXTER ) {
        data.header = 'Convert to Texter';
        data.question = `<div class="px-1">Are you sure you'd like to convert this user to Texter Admin?</div>`;
        data.acceptTxt = 'Yes';
        data.rejectTxt = 'Cancel';
      }
      if (this.currentSeat && this.currentSeat.seatType === seatTypes.TEXTER) {
        data.header = 'Convert to Non Texter';
        data.question = `<div class="px-1">Are you sure you'd like to convert this user to Non Texter Admin?</div>`;
        data.acceptTxt = 'Yes';
        data.rejectTxt = 'Cancel';
      }
      return data;
    },
    integrationLogo() {
      const resIntegration = this.integration &&
      atsIntegrationsList.find((item) => item.type === this.integration.atsType);
      return resIntegration ? resIntegration.headerLogo : null;
    },
    hasInvitedUsers() {
      if (this.currentSeat) {
        return this.currentSeat.status === seatStatus.PENDING_INVITATION ? true :
        this.currentSeat.seatType === seatTypes.NON_TEXTER && this.currentSeat.userId === null;
      } else {
        return false;
      }
    },
    isOutOfOfficeUser() {
      if (this.currentSeat) {
        return !!(this.currentSeat.twilioNumber && this.currentSeat.user.outOfOfficeMessageEnabled);
      } else {
        return false;
      }
    },
    ...mapGetters([
      'userId',
      'twilioNumberFormatted',
      'seatType',
      'outOfOfficeLoaded',
      'totalSeats',
      'usedSeats',
      'userType',
    ]),
    hasPermissionMakeAdmin() {
      if (this.currentSeat && this.currentSeat.user) {
        return this.currentSeat.userType === userTypes.MEMBER;
      } else {
        return false;
      }
    },
    hasPermissionMakeMember() {
      if (this.currentSeat && this.currentSeat.user) {
        return this.currentSeat.userType === userTypes.ADMIN;
      } else {
        return false;
      }
    },
    hasAdminNumber() {
      if (this.currentSeat) {
        return !!(this.currentSeat.twilioNumberFormatted && this.currentSeat.user.id === this.userId);
      } else {
        return false;
      }
    },
    hasNumber() {
      if (this.currentSeat) {
        return !!(this.currentSeat.twilioNumberFormatted && this.currentSeat.user.id !== this.userId);
      } else {
        return false;
      }
    },
  },
  watch: {
    integrationEnabled: {
      handler: function() {
        this.updateTable();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      REMOVE_SEAT_REQUEST,
      CHANGE_USER_TYPE_REQUEST,
      RESET_NUMBER_REQUEST,
      FETCH_USER_OFFICE_MSG_REQUEST,
      UPDATE_USER_OFFICE_MSG_REQUEST,
      UPDATE_USER_TO_TEXTER,
      UPDATE_SEAT_TAGS,
    ]),
    tryRemoveSeat: function() {
      if (this.$refs.deletionConfirmationModal) {
        this.$refs.deletionConfirmationModal.openModal();
      }
    },
    async resendInvitation(seat) {
      const email = !!this.currentSeat.metadata && this.currentSeat.metadata.invitedEmail;
      // const email = !!seat.metadata && seat.metadata.invitedEmail;
      try {
        const res = await resendInvitationApi({email});
        res.status === 'ok' && appNotification.notify({
          title: 'Invite sent!',
          message: 'Ask your team member to check their email',
        });
      } catch { }
    },
    removeSeat: function() {
      this.REMOVE_SEAT_REQUEST({
        payload: {userToRemove: this.currentSeat.userId, email: this.currentSeat.metadata ?
           this.currentSeat.metadata.invitedEmail : null},
        status: this.currentSeat.status,
      });
    },
    changeUserType: function() {
      this.CHANGE_USER_TYPE_REQUEST({
        payload: {id: this.currentSeat.userId, userType: this.currentSeat.userType === userTypes.MEMBER ?
          userTypes.ADMIN : userTypes.MEMBER},
      });
    },
    resetNumber: function() {
      this.RESET_NUMBER_REQUEST({
        payload: {userId: this.currentSeat.userId},
      });
    },
    handleCurrentChange: function(val) {
      this.currentSeat = val;
    },
    clearSelection: function() {
      if (!this.$refs.deletionConfirmationModal.display && !this.$refs.resetNumberModal.display &&
       !this.$refs.makeAdminModal.display && !this.$refs.turnOffOutOfOfficeModal.display &&
       !this.$refs.turnOnTexterModal.display) {
        this.$refs.teamTable.setCurrentRow();
      }
    },
    updateTable: function() {
      if (this.$refs.teamTable) {
        const childrenRefs = this.$refs.teamTable.$children;
        childrenRefs.forEach((child) => child.$forceUpdate());
      }
    },
    tryResetNumber: function() {
      if (this.$refs.resetNumberModal) {
        this.$refs.resetNumberModal.openModal();
      }
    },
    tryChangeRole: function() {
      if (this.$refs.makeAdminModal) {
        this.$refs.makeAdminModal.openModal();
      }
    },
    sortByStatus: function(a, b) {
      const statusA = a.status === seatStatus.PENDING_INVITATION ? 'Invited'.toUpperCase() : a.userId != null ? a.userType.toUpperCase() :
       'Invited'.toUpperCase();
      const statusB = b.status === seatStatus.PENDING_INVITATION ? 'Invited'.toUpperCase() : b.userId != null ? b.userType.toUpperCase() :
      'Invited'.toUpperCase();
      if (statusA < statusB) return -1;
      if (statusA > statusB) return 1;
      return 0;
    },
    sortByName: function(a, b) {
      const fullNameA = a.user ? a.user.firstName + ' ' + ((a.user.lastName) ? a.user.lastName : '') : '';
      const fullNameB = b.user ? b.user.firstName + ' ' + ((b.user.lastName) ? b.user.lastName : '') : '';
      const nameA = fullNameA ? fullNameA.toUpperCase() : '';
      const nameB = fullNameB ? fullNameB.toUpperCase() : '';
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    },
    sortByEmail: function(a, b) {
      const emailA = a.user ? a.user.email.toUpperCase() : a.metadata.invitedEmail.toUpperCase();
      const emailB = b.user ? b.user.email.toUpperCase() : b.metadata.invitedEmail.toUpperCase();
      if (emailA < emailB) return -1;
      if (emailA > emailB) return 1;
      return 0;
    },
    turnOffModalOpen: function() {
      if (this.$refs.turnOffOutOfOfficeModal) {
        this.$refs.turnOffOutOfOfficeModal.openModal();
      }
    },
    turnOnModalOpen: function() {
      this.FETCH_USER_OFFICE_MSG_REQUEST({['userId']: this.currentSeat.userId});
      this.$refs.turnOnOutOfficeModal.displayToggle(true);
    },
    turnOfOffice: function() {
      const payload = {
        outOfOfficeMessageEnabled: false,
        userId: this.currentSeat.userId,
      };
      this.UPDATE_USER_OFFICE_MSG_REQUEST({payload});
    },
    turnOnTexterModalOpen: function() {
      if (this.$refs.turnOnTexterModal) {
        this.$refs.turnOnTexterModal.openModal();
      }
    },
    updateUser: function() {
      const payload = {
        userToUpdate: this.currentSeat.userId,
        currentSeatType: this.currentSeat.seatType,
      };
      this.UPDATE_USER_TO_TEXTER({payload});
    },
    handleEditTags: function(id, row) {
      this.isEditTags = true;
      this.currentEditRow = id;
      this.originalTags = [...row.tags]; // Create a copy of the original tags

      // Focus on the tags-input after the DOM updates
      this.$nextTick(() => {
        const tagsInputComponent = this.$refs.tagsInputRef;
        if (tagsInputComponent && tagsInputComponent.focusInput) {
          tagsInputComponent.focusInput();
        }
      });
    },
    clearTags(row) {
      row.tags = [];
    },
    // Handle saving the tags for a specific row
    saveTags(seatId, row) {
      this.isEditTags = false; // Hide the input after saving
      this.currentEditRow = null; // Reset the current edit row
      const tags = row.tags.map(tag => tag.trim()); // Trim whitespace from each tag
      const payload = {
        tags: tags,
      };
      this.UPDATE_SEAT_TAGS({seatId, payload});
    },
    // Cancel editing and reset
    cancelEditTags(row) {
      if (this.originalTags !== null) {
        // Restore the original tags to prevent clearing
        row.tags = this.originalTags;
      }
      this.isEditTags = false;
      this.currentEditRow = null;
    },
    getTagStyles(row) {
      const maxLines = 2; // Max lines before the tags become scrollable
      const hasTags = row.tags.length > 0;
      const shouldScroll = row.tags.length > maxLines;

      return {
        height: hasTags && shouldScroll ? '88px' : 'auto',
        overflowY: hasTags && shouldScroll ? 'auto' : 'visible',
      };
    },
  },
  created() {
    if (!this.outOfOfficeLoaded) {
      this.FETCH_USER_OFFICE_MSG_REQUEST({['userId']: this.userId});
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/custom/_app-vars.scss";
div.el-table .el-table__row:hover {
  background: #f5f5f5 !important;
}

.members-table {
  .el-table__row {
    cursor: pointer;
  }
}

div.el-table .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.out-of-office-badge {
  text-transform: none;
  background-image: linear-gradient(to right, #EA337F, #901CDD) !important;
  color: #f5f5f5;
  padding: 0.4rem;
}
.invite-sent-badge {
  text-transform: none;
  background-image: linear-gradient(to right, #EA337F, #901CDD) !important;
  color: #f5f5f5;
  padding: 0.4rem;
}
</style>
